import { whiteColor } from 'config/variables';
import { makeStyles, Theme } from '@material-ui/core/styles';
import { blueGrey } from '@material-ui/core/colors';

export const useTreeItemStyles = makeStyles<Theme>(theme => ({
  root: {
    color: theme.palette.text.secondary,
    '&:hover > $content': {
      backgroundColor: (props: any) => (props.selected !== null ? '#e1e1e1' : 'transparent'),
    },
    '&$selected&:focus $label': {
      backgroundColor: 'transparent',
    },
    '&$selected > $content': {
      fontWeight: 'bold',
    },
    '&:focus > $content $label, &$selected $content $label:hover, &$selected > $content $label': {
      backgroundColor: 'transparent',
    },
    '&:not(.Mui-expanded) .item-count': {
      background: ({ selected, level }: any) => {
        return !selected ? 'rgba(255,255,255, .4)' : level === 0 ? '#fff' : null;
      },
    },
  },
  content: {
    color: theme.palette.text.secondary,
    transition: 'all .2s ease',
    flexDirection: 'row-reverse',
    height: 53,
    [theme.breakpoints.down('sm')]: {
      height: 40,
    },
    backgroundColor: (props: any) => (props.selected === null ? 'inherit' : '#F5F5F5'),
  },
  group: {
    marginLeft: 0,
    '& $content': {
      paddingLeft: theme.spacing(2),
    },
  },
  expanded: {},
  selected: {},
  label: {
    fontWeight: 'inherit',
    color: 'inherit',
    paddingLeft: 60,
    [theme.breakpoints.down('sm')]: {
      paddingLeft: 0,
      textAlign: 'center',
    },
    fontSize: 22,
    [theme.breakpoints.down('md')]: {
      fontSize: 19,
    },
    '&:hover': {
      backgroundColor: 'transparent',
    },
  },
  iconContainer: {
    marginRight: 35,
  },
  divider: {
    backgroundColor: whiteColor,
    height: (props: any) => (props.parent_id ? 1 : 3),
  },
  treeRoot: {
    position: 'sticky',
    [theme.breakpoints.up('md')]: {
      maxHeight: '100vh',
      overflowY: 'scroll',
      overflowX: 'hidden',
      paddingRight: 48,
      width: 'calc(100% + 48px)',
      scrollbarWidth: 'none',
      '&::-webkit-scrollbar': {
        display: 'none',
      },
    },
    top: 0,
    '& > li.Mui-expanded > $content': {
      [theme.breakpoints.up('md')]: {
        width: 'calc(100% + 40px)',
      },
    },
    '& > li ul li.Mui-expanded > $content': {
      [theme.breakpoints.up('md')]: {
        width: 'calc(100% + 20px)',
      },
    },
    '& li': {
      '&.Mui-expanded $content': {
        backgroundColor: 'inherit',
      },
      '&.Mui-expanded > $content': {
        [theme.breakpoints.up('sm')]: {
          borderTopRightRadius: theme.spacing(6),
          borderBottomRightRadius: theme.spacing(6),
        },
      },
    },
    '& > li:last-child': {
      borderBottom: '3px white solid',
    },
    '& > li > $content': {
      height: 100,
      [theme.breakpoints.down('sm')]: {
        height: 88,
      },
      '& $label': {
        fontSize: '25px',
        fontWeight: 'bold',
        textTransform: 'uppercase',
        [theme.breakpoints.down('md')]: {
          fontSize: '21px',
        },
        [theme.breakpoints.down('sm')]: {
          fontSize: '18px',
        },
      },
    },
    '& ul $label': {
      textAlign: 'left',
      [theme.breakpoints.down('sm')]: {
        fontSize: '16px',
      },
    },
    '& ul ul $label': {
      paddingLeft: 80,
      [theme.breakpoints.down('sm')]: {
        paddingLeft: 20,
      },
    },
    '& ul ul ul $label': {
      paddingLeft: 100,
      [theme.breakpoints.down('sm')]: {
        paddingLeft: 40,
      },
    },
  },
  link: {
    verticalAlign: 'middle',
    textDecoration: 'none',
    color: blueGrey['500'],
    lineHeight: '28px',
    height: 28,
    marginLeft: 10,
    '&:hover': {
      color: blueGrey['700'],
    },
  },
  expandButton: {
    border: 'none',
    background: 'none',
    cursor: 'pointer',
    outline: 'none',
    color: '#B2B2B2',
    fontSize: 12,
    fontWeight: 600,
    textTransform: 'uppercase',
    display: 'flex',
    alignItems: 'center',
    marginTop: '-42px',
    marginBottom: '10px',
    '& span': {
      display: 'flex',
      width: 30,
      height: 30,
      background: whiteColor,
      marginRight: 15,
      borderRadius: '50%',
      justifyContent: 'center',
      alignItems: 'center',
    },
    '& svg': {
      color: '#B2B2B2',
    },
  },
  itemCount: {
    display: 'inline-block',
    background: ({ level }: any) => (level >= 2 ? '#fff' : 'rgba(255,255,255, .4)'),
    transition: '.5s',
    fontSize: 12,
    fontWeight: 500,
    borderRadius: '50%',
    width: 30,
    height: 30,
    lineHeight: '30px',
    textAlign: 'center',
    verticalAlign: 'middle',
    marginLeft: 8,
    marginTop: '-5px',
    [theme.breakpoints.down('sm')]: {
      width: 25,
      height: 25,
      lineHeight: '25px',
    },
    '&.collapse': {
      display: 'flex',
      background: '#fff',
      margin: '0 0 0 10px',
      textAlign: 'center',
      justifyContent: 'center',
    },
  },
}));
