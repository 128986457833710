import api from 'config/axios';

export function list() {
  return api.get('/categories');
}

export function item(id: any) {
  return api.get(`/categories/${id}`);
}

export function add(data: any) {
  return api.post('/categories', data);
}

export function edit(id: string, data: any) {
  return api.patch(`/categories/${id}`, data);
}

export function del(id: string) {
  return api.delete(`/categories/${id}`);
}

export function move(id: string, direction: any) {
  return api.post(`/categories/move/${id}`, direction);
}
