import React, { memo, useRef } from 'react';

import { useQuery } from 'react-query';
import { list } from '../../api/type';
import messages from '../../messages/resources';
import { useIntl } from 'react-intl';
import Select from 'react-select';
import { Field } from 'redux-form';
import FormInput from '../common/FormInput';
import Loader from '../common/Loader';

function TypesSelect({ homePage, searchWithinCategory, filter, ...props }: any) {
  const { data = { data: [] }, isLoading } = useQuery(
    ['typeList', { searchWithinCategory: Number(searchWithinCategory), ...filter }],
    (key, params) => {
      return list({ params });
    },
  );
  const { data: types } = data;
  const { formatMessage } = useIntl();
  const ref = useRef<any>([]);

  if (types.length) {
    ref.current = types.map(({ id, type, __meta__ }: any) => ({
      label: type,
      value: id,
      count: __meta__?.resources_count || 0,
    }));
  }

  const { current: options } = ref;
  return homePage ? (
    <Select
      {...props}
      placeholder={formatMessage(messages.fieldType)}
      options={[{ value: null, label: 'All' }, ...options]}
      value={options.filter((item: any) => item.value === Number(props.value))}
      styles={props.styles}
    />
  ) : (
    <Loader loading={isLoading && !options}>
      <Field
        isMulti
        name="types"
        type="reactSelect"
        component={FormInput}
        options={options}
        placeholder={formatMessage(messages.fieldType)}
        styles={props.styles}
      />
    </Loader>
  );
}

export default memo(TypesSelect);
