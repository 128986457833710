import React, { memo } from 'react';
import cx from 'classnames';

import makeStyles from '@material-ui/core/styles/makeStyles';
import AlertMaterial from '@material-ui/lab/Alert';

import ErrorIcon from '@material-ui/icons/ReportProblemOutlined';
import SuccessIcon from '@material-ui/icons/CheckOutlined';
import WarningIcon from '@material-ui/icons/WarningOutlined';
import InfoIcon from '@material-ui/icons/InfoOutlined';

import styles from '../styles';

const useStyles = makeStyles(styles);

export interface IAlertProps {
  children: React.ReactNode;
  severity: 'error' | 'success' | 'warning' | 'info';
  onClose: () => void;
  className?: string;
}

function Alert({ children, severity, onClose, className = '' }: IAlertProps) {
  const classes = useStyles();

  const alertClasses = cx(
    classes.alertRoot,
    className,
    severity ? `${classes[severity]} alert-${severity}` : '',
    'alert',
  );
  return (
    <AlertMaterial
      onClick={onClose}
      classes={{
        root: alertClasses,
        icon: classes.alertIcon,
        message: classes.alertMessage,
        action: classes.alertAction,
      }}
      onClose={onClose}
      variant="outlined"
      severity={severity}
      iconMapping={{
        error: <ErrorIcon fontSize="inherit" />,
        success: <SuccessIcon fontSize="inherit" />,
        warning: <WarningIcon fontSize="inherit" />,
        info: <InfoIcon fontSize="inherit" />,
      }}
    >
      <div>{children}</div>
    </AlertMaterial>
  );
}

export default memo(Alert);
