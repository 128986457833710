import { put, call, takeLatest } from 'redux-saga/effects';
import { push } from 'connected-react-router';
import { Action } from 'redux-actions';
import { AuthActions } from '../actions';
import { ILogin, IAuth, AnyObject as IError } from '../../types';
import { login, resetPasswordRequest, resetPasswordConfirm } from './api';
import { error, success } from 'components/snackbars/utils';
import intl from 'utils/injectIntl';
import authMessages from 'messages/auth';
import appMessages from 'messages';
import { stopSubmit } from 'redux-form';

const { formatMessage } = intl;

function* LoginWorker(action: Action<ILogin>) {
  try {
    const {
      data: {
        data: { token },
      },
    } = yield call(login, action.payload);
    localStorage.setItem('token', token);
    yield put(AuthActions.setAuthInfo({ token } as IAuth));
    yield put(push('/admin'));
  } catch (e) {
    if (e.response?.data) {
      yield put(stopSubmit('login', e.response?.data?.error?.details));
      yield put(AuthActions.setAuthError({ error: e.response.data } as IError));
    } else {
      error(formatMessage(appMessages.errorSomethingWentWrong));
    }
  }
}

function* LogoutWorker(action: Action<ILogin>) {
  localStorage.removeItem('token');
  yield put(AuthActions.setAuthInfo({ token: '' } as IAuth));
  yield put(push('/login'));
}

function* ResetPasswordWorker(action: any) {
  const { messages, ...payload } = action.payload;
  try {
    yield call(resetPasswordRequest, payload);
    success(formatMessage(authMessages.resetPasswordSent));
  } catch (e) {
    if (e.response?.data) {
      yield put(AuthActions.setAuthError({ error: e.response?.data } as IError));
    } else {
      error(formatMessage(appMessages.errorSomethingWentWrong));
    }
  }
}

function* ResetPasswordConfirmWorker(action: any) {
  const { messages, ...payload } = action.payload;
  try {
    yield call(resetPasswordConfirm, payload);
    success(formatMessage(authMessages.resetPasswordSuccess));
    yield put(push('/login'));
  } catch (e) {
    if (e.response && e.response.status === 404) {
      error(formatMessage(authMessages.errorsTokenExpired));
    } else {
      error(formatMessage(appMessages.errorSomethingWentWrong));
    }
  }
}

export default function* watchAuth() {
  yield takeLatest(AuthActions.Type.LOGIN, LoginWorker);
  yield takeLatest(AuthActions.Type.LOGOUT, LogoutWorker);
  yield takeLatest(AuthActions.Type.RESET_PASSWORD_REQUEST, ResetPasswordWorker);
  yield takeLatest(AuthActions.Type.RESET_PASSWORD_CONFIRM, ResetPasswordConfirmWorker);
}
