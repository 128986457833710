import { defineMessages } from 'react-intl';

export const scope = 'auth';

export default defineMessages({
  titleLogin: {
    id: `${scope}.title.login`,
    defaultMessage: 'Login',
  },
  titleForgotPassword: {
    id: `${scope}.title.forgot`,
    defaultMessage: 'Forgot password',
  },
  admin: {
    id: `${scope}.role.admin`,
    defaultMessage: 'Admin',
  },
  companyAdmin: {
    id: `${scope}.role.companyAdmin`,
    defaultMessage: 'Company Admin',
  },
  companyUser: {
    id: `${scope}.role.companyUser`,
    defaultMessage: 'Company User',
  },
  recruiter: {
    id: `${scope}.role.recruiter`,
    defaultMessage: 'Recruiter',
  },
  resetPasswordSuccess: {
    id: `${scope}.resetPassword.updated.success`,
    defaultMessage: 'Password has been reset',
  },
  resetPasswordSent: {
    id: `${scope}.resetPassword.sent.success`,
    defaultMessage: 'Reset password link has been sent',
  },
  errorsTokenExpired: {
    id: `${scope}.errors.token.expired`,
    defaultMessage: 'Your token is expired.',
  },
});
