import { Theme } from '@material-ui/core';

// Variables - Styles
const fontFamily = '"Rubik", Arial, sans-serif';

const boxShadow = {
  boxShadow: '0px 4px 10px rgba(0, 0, 0, 0.25)',
};
const boxShadowHover = {
  boxShadow: '0px 4px 10px rgba(0, 0, 0, 0.15)',
};

const transition = {
  transition: 'all 0.2s cubic-bezier(0.685, 0.0473, 0.346, 1)',
};

const layoutHorizontalIndent = (theme: Theme) => ({
  paddingLeft: 16,
  paddingRight: 16,
  [theme.breakpoints.up('sm')]: {
    paddingLeft: 20,
    paddingRight: 20,
  },
  [theme.breakpoints.up('md')]: {
    paddingLeft: 32,
    paddingRight: 32,
  },
});

// Sizes
const drawerWidth = 240;
const textFieldHeight = 36;
const textFieldFontSize = 14;
const sliderHeight = 4;
const borderRadiusSmallSize = 4;
const borderRadiusSize = 6;

// System Color
const whiteColor = '#FFF';
const primaryColor = '#4E79E9';
const primaryColorDark = '#456CD0';
const primaryColorDisable = '#AFBEE3';
const secondaryColor = '#EDF2FD';
const secondaryColorDark = '#DCE4FB';
const secondaryColorDisable = '#EFF1F8';
const successColor = '#19BF6F';
const successColorDark = '#19AB4F';
const successSecondaryColor = '#E8F9F1';
const successSecondaryColorDark = '#CAF5E1';
const dangerColor = '#E9604E';
const dangerColorDark = '#D55E4C';
const dangerSecondaryColor = '#FDEFED';
const dangerSecondaryColorDark = '#FFDFDC';
const warningColor = '#E68819';
const waitingColor = '#DFBF13';
const disableColor = '#D1D1DC';
const greyColor = '#8F91A0';
const lightGreyColor = '#F6F5F6';
const lighterGreyColor = '#F9F9F9';
const infoColor = '#BCE3FF';

// Status Color
const activeColor = '#E8F9F1';
const activeTextColor = '#19BF6F';
const inactiveColor = '#FDEFED';
const inactiveTextColor = '#E9604E';
const newColor = '#FCF9E8';
const newTextColor = '#DFBF13';
const searchColor = '#FCF3E8';
const searchTextColor = '#E68819';
const interviewColor = '#F1EDFD';
const interviewTextColor = '#764EE9';
const closedColor = '#EDF2FD';
const closedTextColor = '#4E79E9';
const draftColor = '#F4F4F5';
const draftTextColor = '#8F91A0';

// Layout Background Color
const bgBody = '#FCFCFC';
const bgGrey = '#F0EFF0';

// Avatar Colors
const avatarColors = ['#85A7FF', '#FFBB6B', '#AA8CFF', '#F7DE59', '#83BCFF', '#51DD9A'];

// Button
const btnPrimaryColorOutlineHover = '#E9EFFF';
const btnSecondaryColorOutlineHover = '#F5F7FF';

// Text Color
const textPrimaryColor = '#2F3350';
const textSecondaryColor = '#8F91A0';
const labelColor = '#696B80';
const textGreyColor = '#696B80';
const textLightGreyColor = '#C6C7D0';

// Border Color
const borderPrimaryColor = '#DDDDE0';
const borderSecondaryColor = '#E7EBEE';

const frontColors = [
  ['#89E2D9', '#B4F2EC', '#E9FEFC', '#F9FFFE'],
  ['#76BCE5', '#9ED6F6', '#D0E5F2', '#F9FDFF'],
  ['#B3A1C5', '#D5C7E3', '#EEE4F8', '#FEFCFF'],
  ['#FFE19F', '#FFECC2', '#FFF7E6', '#FFFEFB'],
  ['#F69DA0', '#F9C1C3', '#FFE4E4', '#FFF8F8'],
];

export {
  fontFamily,
  boxShadow,
  boxShadowHover,
  transition,
  layoutHorizontalIndent,
  // Sizes
  drawerWidth,
  textFieldHeight,
  textFieldFontSize,
  sliderHeight,
  borderRadiusSmallSize,
  borderRadiusSize,
  // System Color
  whiteColor,
  primaryColor,
  primaryColorDark,
  primaryColorDisable,
  secondaryColor,
  secondaryColorDark,
  secondaryColorDisable,
  successColor,
  successColorDark,
  successSecondaryColor,
  successSecondaryColorDark,
  dangerColor,
  dangerColorDark,
  dangerSecondaryColor,
  dangerSecondaryColorDark,
  warningColor,
  waitingColor,
  disableColor,
  greyColor,
  lightGreyColor,
  lighterGreyColor,
  infoColor,
  // Status Color
  activeColor,
  activeTextColor,
  inactiveColor,
  inactiveTextColor,
  newColor,
  newTextColor,
  searchColor,
  searchTextColor,
  interviewColor,
  interviewTextColor,
  closedColor,
  closedTextColor,
  draftColor,
  draftTextColor,
  // Layout Background Color
  bgBody,
  bgGrey,
  // Button
  btnPrimaryColorOutlineHover,
  btnSecondaryColorOutlineHover,
  // Text Color
  textPrimaryColor,
  textSecondaryColor,
  labelColor,
  textGreyColor,
  textLightGreyColor,
  // Border Color
  borderPrimaryColor,
  borderSecondaryColor,
  // Avatar Colors
  avatarColors,
  frontColors,
};
