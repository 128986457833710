import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(theme => ({
  avatar: {
    margin: 'auto',
    height: 150,
    width: 150,
    [theme.breakpoints.down('sm')]: {
      margin: 10,
      width: 120,
      height: 120,
      display: 'flex',
    },
  },
  menu: {
    display: 'flex',
    color: '#808080',
    width: 62,
    height: 62,
    cursor: 'pointer',
    outline: 'none',
  },
  logoWrapper: {
    padding: '80px 0',
    [theme.breakpoints.down('sm')]: {
      padding: '20px 0',
      display: 'flex',
      justifyContent: 'space-between',
    },
  },
  bg: {
    [theme.breakpoints.up('md')]: {
      backgroundColor: '#F5F5F5',
    },
  },
  search: {
    border: '3px #EBEBEB solid',
    borderRadius: 26,
    height: 53,
    paddingLeft: 12,
    fontSize: 22,
    '& svg': {
      fontSize: 38,
      color: '#808080',
    },
  },
  content: {
    minHeight: 450,
  },
  checkbox: {
    fontSize: 18,
    color: '#8E8E8E',
  },
  icon: {
    borderRadius: 11,
    width: 33,
    height: 33,
    border: '#EBEBEB 2px solid',
    '$root.Mui-focusVisible &': {
      outline: '2px auto rgba(19,124,189,.6)',
      outlineOffset: 2,
    },
    'input:disabled ~ &': {
      boxShadow: 'none',
      background: 'rgba(206,217,224,.5)',
    },
  },
  checkedIcon: {
    backgroundImage: 'linear-gradient(180deg,hsla(0,0%,100%,.1),hsla(0,0%,100%,0))',
    '&:before': {
      display: 'block',
      width: 33,
      height: 33,
      backgroundImage: `url("data:image/svg+xml;charset=utf-8,%3Csvg xmlns=\\'http://www.w3.org/2000/svg\\' viewBox='1 1 16 16'%3E%3Cpath fill-rule='evenodd' clip-rule='evenodd' d='M12 5c-.28 0-.53.11-.71.29L7 9.59l-2.29-2.3a1.003 1.003 0 00-1.42 1.42l3 3c.18.18.43.29.71.29s.53-.11.71-.29l5-5A1.003 1.003 0 0012 5z' fill='%23808080'/%3E%3C/svg%3E")`,
      content: '""',
    },
  },
}));

export default useStyles;
