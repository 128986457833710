import React from 'react';

import Alert, { IAlertProps } from 'components/alert/components/Alert';

const Message = React.forwardRef((props: IAlertProps, ref: any) => {
  return (
    <span ref={ref}>
      <Alert {...props} />
    </span>
  );
});

export default Message;
