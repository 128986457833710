import React, { useCallback, useState } from 'react';
import { useLocation } from 'react-router-dom';
import cx from 'classnames';
import queryString from 'query-string';
import { useQuery } from 'react-query';
import Intercom from 'react-intercom';

import { Paper, Box, Avatar, Grid, Link, Hidden } from '@material-ui/core';
import MenuRoundedIcon from '@material-ui/icons/MenuRounded';
import Pagination from '@material-ui/lab/Pagination/Pagination';

import Logo from 'assets/logo.svg';
import Loader from 'components/common/Loader';
import { list as resourceList } from 'api/resource';
import { list as categoryList } from 'api/category';
import Resource from 'components/resources/Resource';
import CategoryTree from 'components/resources/CategoryTree';
import Search from 'components/resources/Search';
import LanguagesSelect from 'components/resources/LanguagesSelect';
import TypesSelect from 'components/resources/TypesSelect';
import { intercomId } from 'components/resources/constants';
import filterStyles from 'components/resources/filterStyles';
import useDebounce from 'utils/useDebounce';
import { useCleanQueryString } from 'components/resources/hooks';
import Drawer from 'components/resources/Drawer';
import SelectedCategories from 'components/resources/SelectedCategories';
import useStyles from './styles';
import SearchCheckbox from '../../components/resources/SearchCheckbox';
import ResourceTypesSelect from '../../components/resources/ResourceTypesSelect';

export default function HomePage() {
  const classes = useStyles();
  const location = useLocation();
  const {
    page = 1,
    search = '',
    category = null,
    language = null,
    type = null,
    resource_type = null,
  } = queryString.parse(location.search);
  const initialState = {
    search,
    category,
    language,
    type,
    resource_type,
    page: Number(page),
  };
  const [searchWithinCategory, setSearchWithinCategory] = useState(false);
  const [filter, setFilter] = useState<any>(initialState);
  const debouncedSearchTerm = useDebounce(filter.search, 500);
  useCleanQueryString(filter, location.pathname);

  const handleSetFilter = useCallback(
    (key: any, value: any) => {
      const newObj = key === 'category' && category !== value ? { search: '' } : { ...filter };
      newObj[key] = value;
      setFilter(newObj);
    },
    [filter, category],
  );

  const handleSearch = useCallback(
    (e: any) => {
      handleSetFilter('search', e.target.value);
    },
    [handleSetFilter],
  );

  const handlePageChange = useCallback(
    (e, page: any) => {
      handleSetFilter('page', page);
    },
    [handleSetFilter],
  );

  const filterModified = { ...filter };
  if (!searchWithinCategory && (filter.search.length || filter.type || filter.language || filter.resource_type)) {
    delete filterModified.category;
  }
  const { data: resourcesData = { data: { data: [], lastPage: 0 } }, isLoading: rLoading } = useQuery(
    ['resourceList', { ...filterModified, search: debouncedSearchTerm }],
    (key, params) => resourceList({ params }),
  );

  const { data: categoriesData = { data: [] }, isLoading: cLoading } = useQuery('categoryList', () => categoryList());
  const {
    data: { data: resources, lastPage },
  } = resourcesData;
  const { data: categories } = categoriesData;
  const [drawerOpen, setDrawerOpen] = useState(false);

  return (
    <Grid container spacing={0} justify="center">
      <Grid item xs={10} md={4}>
        <Paper elevation={0} className={cx(classes.logoWrapper, classes.bg)}>
          <Link href="/">
            <Avatar src={Logo} className={classes.avatar} />
          </Link>
          <Hidden mdUp>
            <MenuRoundedIcon className={classes.menu} onClick={() => setDrawerOpen(true)} />
          </Hidden>
        </Paper>
      </Grid>
      <Grid component={Box} display={{ xs: 'none', sm: 'none', md: 'block' }} item xs={8}>
        <Box pt={10} px={2}>
          <Grid container spacing={3} justify="center">
            <Grid item>
              <Search handleSearch={handleSearch} searchString={filter.search} className={classes.search} />
            </Grid>
            <Grid item>
              <LanguagesSelect
                homePage
                searchWithinCategory={searchWithinCategory}
                filter={{ language: filter.language, type: filter.type, resource_type: filter.resource_type }}
                value={filter.language}
                type="reactSelect"
                styles={filterStyles}
                onChange={(v: any) => handleSetFilter('language', v ? v.value : v)}
              />
            </Grid>
            <Grid item>
              <TypesSelect
                homePage
                searchWithinCategory={searchWithinCategory}
                filter={{ language: filter.language, type: filter.type, resource_type: filter.resource_type }}
                value={filter.type}
                type="reactSelect"
                styles={filterStyles}
                onChange={(v: any) => handleSetFilter('type', v ? v.value : v)}
              />
            </Grid>
            <Grid item>
              <ResourceTypesSelect
                homePage
                searchWithinCategory={searchWithinCategory}
                filter={{ language: filter.language, type: filter.type, resource_type: filter.resource_type }}
                value={filter.resource_type}
                onChange={(v: any) => handleSetFilter('resource_type', v ? v.value : v)}
                placeholder="Resource Type"
                styles={filterStyles}
              />
            </Grid>
          </Grid>
          <Box display="flex" justifyContent="center" mt={3}>
            <SearchCheckbox checked={searchWithinCategory} setChecked={setSearchWithinCategory} />
          </Box>
        </Box>
      </Grid>
      <Grid component={Box} display={{ xs: 'none', sm: 'none', md: 'block' }} item xs={4} className={classes.bg}>
        <Loader loading={cLoading}>
          <CategoryTree selectedCat={filter.category} categories={categories} handleSetFilter={handleSetFilter} />
        </Loader>
      </Grid>
      <Grid item xs={12} md={8}>
        <Grid container justify="center">
          <Grid item xs={10} className={classes.content}>
            <Loader loading={rLoading || cLoading}>
              <Hidden mdUp>
                <SelectedCategories categoryList={categories} selectedCategory={filter.category} />
              </Hidden>
              {resources.length ? (
                <>
                  {resources.map((resource: any) => (
                    <Resource key={resource.id} {...resource} categoryList={categories} selectedCat={filter.category} />
                  ))}
                  <Box display="flex" justifyContent="center" mt={3}>
                    {lastPage !== 1 && <Pagination count={lastPage} page={filter.page} onChange={handlePageChange} />}
                  </Box>
                </>
              ) : (
                <Box
                  p={5}
                  textAlign="center"
                  border={1}
                  fontSize={24}
                  color="text.secondary"
                  borderRadius="borderRadius"
                  borderColor="#0000001f"
                  bgcolor="#F9FDFF"
                >
                  NO ITEMS FOUND
                </Box>
              )}
            </Loader>
          </Grid>
        </Grid>
        <Drawer
          drawerOpen={drawerOpen}
          setDrawerOpen={setDrawerOpen}
          filter={filter}
          categories={categories}
          category={category}
          setFilter={setFilter}
          searchWithinCategory={searchWithinCategory}
          setSearchWithinCategory={setSearchWithinCategory}
        />
      </Grid>
      <Grid item xs={12}>
        <Box fontSize={19} textAlign="center" p={6}>
          Questions? Write to us at help@guardyoureyes.org or click here to start a chat.
          <br />© 2020 GYE Corp. P.O. Box 32380, Pikesville, MD 21282, U.S.A.
        </Box>
        <Intercom appID={intercomId} />
      </Grid>
    </Grid>
  );
}
