import React, { memo, useRef } from 'react';

import { useQuery } from 'react-query';
import { resourceTypeList } from 'api/type';
import messages from '../../messages/resources';
import { useIntl } from 'react-intl';
import Select from 'react-select';

function ResourceTypesSelect({ homePage, searchWithinCategory, filter, ...props }: any) {
  const { data: { data: resourceTypes } = { data: [] } } = useQuery(
    ['resourceTypeList', { searchWithinCategory: Number(searchWithinCategory), ...filter }],
    (key, params) => {
      return resourceTypeList({ params });
    },
  );
  const { formatMessage } = useIntl();
  const ref = useRef<any>([]);

  if (resourceTypes.length) {
    ref.current = resourceTypes.map(({ id, resource_type, __meta__ }: any) => ({
      label: resource_type,
      value: id,
      count: __meta__?.resources_count || 0,
    }));
  }

  const { current: options } = ref;
  return (
    <Select
      {...props}
      placeholder={formatMessage(messages.fieldResourceType)}
      options={[{ value: null, label: 'All' }, ...options]}
      value={options.filter((item: any) => item.value === Number(props.value))}
      styles={props.styles}
    />
  );
}

export default memo(ResourceTypesSelect);
