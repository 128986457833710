import { combineReducers } from 'redux';
import { connectRouter } from 'connected-react-router';
import { reducer as formReducer } from 'redux-form';
import { UserReducer } from './user';
import { IRootState } from './state';
import { AuthReducer } from './auth';
import { ResourceReducer } from './resource';

// NOTE: current type definition of Reducer in 'redux-actions' module
// doesn't go well with redux@4
const createRootReducer = (history: any) =>
  combineReducers<IRootState>({
    router: connectRouter(history),
    user: UserReducer as any,
    auth: AuthReducer as any,
    resource: ResourceReducer as any,
    form: formReducer as any,
  });

export default createRootReducer;
