import { handleActions } from 'redux-actions';
import { ResourceActions } from '../actions';

export const initialState = {
  queryString: '',
};

export const ResourceReducer = handleActions(
  {
    [ResourceActions.Type.SET_QUERY_STRING]: (state, action) => action.payload,
  },
  initialState,
);
