import React from 'react';

export interface Props {
  children?: React.ReactNode;
}

function HomePageLayout(props: any) {
  const { children } = props;
  return children;
}

export default HomePageLayout;
