import React, { memo, useCallback, useContext, useEffect, useMemo, useRef, useState } from 'react';
import isEqual from 'lodash.isequal';

import { Box, Divider } from '@material-ui/core';
import AddIcon from '@material-ui/icons/Add';
import RemoveIcon from '@material-ui/icons/Remove';

import TreeView from '@material-ui/lab/TreeView';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import ChevronRightIcon from '@material-ui/icons/ChevronRight';
import TreeItem from '@material-ui/lab/TreeItem';

import { frontColors } from 'config/variables';
import findItemNested, { countNested, findItemsWithChildren, findParent } from 'utils/findItemNested';
import { useTreeItemStyles } from './styles';
import { Link } from 'react-router-dom';
import AddCircleOutlineIcon from '@material-ui/icons/AddCircleOutline';
import UserTypeContext from '../context/userType';
import cx from 'classnames';

interface RenderTree {
  id: string;
  name: string;
  color: string;
  children?: RenderTree[];
}

const StyledTreeItem = memo(
  ({ id, name, color, selected = null, level, ...other }: any) => {
    const classes = useTreeItemStyles({ selected, level, parent_id: other.parent_id });
    const userType = useContext(UserTypeContext);
    return (
      <>
        <Divider classes={{ root: classes.divider }} />
        <TreeItem
          label={
            <>
              {name} <span className={cx('item-count', classes.itemCount)}>{other.resource_count}</span>
              {userType === 'admin' && (
                <Link to={`/admin/resources/add?category=${id}`} className={classes.link}>
                  <AddCircleOutlineIcon />
                </Link>
              )}
            </>
          }
          style={{
            backgroundColor: color,
          }}
          classes={{
            root: classes.root,
            content: classes.content,
            expanded: classes.expanded,
            selected: classes.selected,
            group: classes.group,
            label: classes.label,
            iconContainer: classes.iconContainer,
          }}
          nodeId={id.toString()}
          {...other}
        />
      </>
    );
  },
  (prevProps, nextProps) =>
    prevProps.children?.length === nextProps.children?.length &&
    prevProps.selected === nextProps.selected &&
    prevProps.resource_count === nextProps.resource_count,
);

function CategoryTree({ categories, selectedCat, handleSetFilter }: any) {
  const [expandAll, setExpandAll] = useState(false);
  const expandedCats = findParent(categories, Number(selectedCat), true);
  const [expanded, setExpanded] = useState<string[]>(expandedCats);
  const [selected, setSelected] = useState<string>(selectedCat ? selectedCat : '');
  const handleToggle = useCallback((event: React.ChangeEvent<{}>, nodeIds: string[]) => {
    setExpanded(nodeIds);
  }, []);
  const handleSelect = useCallback(
    (event: React.ChangeEvent<{}>, nodeId: string) => {
      setSelected(nodeId);
      handleSetFilter('category', nodeId);
    },
    [handleSetFilter],
  );

  useEffect(() => {
    if (selected.length) {
      const selectedObj = findItemNested(categories, Number(selected), 'children');
      if (selectedObj && !selectedObj.parent_id && selected && !expanded.includes(selected)) {
        handleSetFilter('category', null);
      }
    }
  }, [categories, expanded, handleSetFilter, selected]);

  // Collapse other categories
  useEffect(() => {
    const parents = findParent(categories, Number(selected), true);
    const newExpanded = expanded.filter(item => parents.includes(item));
    if (!isEqual(expanded, newExpanded) && !expandAll) {
      setExpanded(newExpanded);
    }
  }, [categories, expandAll, expanded, selected]);

  const count = useMemo(() => countNested(categories, 'children'), []);
  const renderTree = (node: RenderTree, branch: number, level: number = 0, selected = '') => {
    const color = frontColors[branch][level];
    return (
      <StyledTreeItem key={node.id} {...node} color={color} selected={selected} level={level}>
        {Array.isArray(node.children)
          ? node.children.map((childNode: any) => renderTree(childNode, branch, level + 1, selected))
          : null}
      </StyledTreeItem>
    );
  };

  const handleExpandAll = useCallback(() => {
    if (!expandAll) {
      setExpanded(findItemsWithChildren(categories));
    } else {
      setSelected('');
      setExpanded([]);
      handleSetFilter('category', null);
    }
    setExpandAll(prev => !prev);
  }, [categories, expandAll, handleSetFilter]);

  let branch = -1;
  const classes = useTreeItemStyles();
  const userType = useContext(UserTypeContext);

  return (
    <>
      <Box pl={7} mt={userType === 'admin' ? 5 : 0}>
        <button onClick={handleExpandAll} className={classes.expandButton}>
          {expandAll ? (
            <>
              <span>
                <RemoveIcon fontSize="small" />
              </span>{' '}
              Collapse All <div className={cx('collapse', classes.itemCount)}>{count}</div>
            </>
          ) : (
            <>
              <span>
                <AddIcon fontSize="small" />
              </span>{' '}
              Expand All <div className={cx('collapse', classes.itemCount)}>{count}</div>
            </>
          )}
        </button>
      </Box>
      <TreeView
        classes={{
          root: classes.treeRoot,
        }}
        defaultCollapseIcon={<ExpandMoreIcon style={{ fontSize: 40 }} />}
        defaultExpandIcon={<ChevronRightIcon style={{ fontSize: 40 }} />}
        expanded={expanded}
        selected={selected}
        onNodeToggle={handleToggle}
        onNodeSelect={handleSelect}
      >
        {categories.map((item: any) => {
          branch += 1;
          return renderTree(item, branch, 0, selectedCat);
        })}
      </TreeView>
    </>
  );
}

export default memo(CategoryTree, (prevProps, nextProps) => {
  if (nextProps?.userType === 'admin') {
    return isEqual(prevProps.categories, nextProps.categories);
  }
  return prevProps.selectedCat === nextProps.selectedCat && prevProps.expandAll === nextProps.expandAll;
});
