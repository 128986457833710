const styles = {
  container: (styles: any) => ({
    ...styles,
    display: 'inline-flex',
    verticalAlign: 'top',
  }),
  control: (styles: any) => ({
    ...styles,
    backgroundColor: '#F5F5F5',
    border: '3px #EBEBEB solid',
    borderRadius: 26,
    height: 53,
    padding: '0 12px',
    minWidth: '250px',
    borderColor: '#EBEBEB',
    boxShadow: 'none',
    '&:hover': {
      borderColor: '#EBEBEB',
      boxShadow: 'none',
    },
  }),
  menu: (styles: any) => ({
    ...styles,
    zIndex: 2,
  }),
  option: (styles: any, { isDisabled, isFocused, isSelected, ...rest }: any) => {
    const after =
      rest.data.value && typeof rest.data.count !== 'undefined'
        ? {
            ':after': {
              borderRadius: '50%',
              backgroundColor: 'rgba(255,255,255, .35)',
              content: `"${rest.data.count}"`,
              display: 'inline-block',
              marginLeft: 8,
              fontSize: 12,
              textAlign: 'center',
              lineHeight: '20px',
              height: 20,
              width: 20,
            },
          }
        : {};
    return {
      ...styles,
      ...after,
      backgroundColor: isDisabled ? null : isSelected ? '#634E79' : isFocused ? 'rgba(99,78,121,0.2)' : null,
      ':active': {
        ...styles[':active'],
        backgroundColor: !isDisabled && (isSelected ? '#634E79' : 'rgba(99,78,121,0.2)'),
      },
    };
  },
  indicatorSeparator: (styles: any) => ({ ...styles, display: 'none' }),
  placeholder: (styles: any) => ({
    ...styles,
    fontSize: 22,
    color: '#7d7d7d',
  }),
  singleValue: (styles: any) => ({
    ...styles,
    fontSize: 22,
    color: '#7d7d7d',
  }),
  clearIndicator: (styles: any) => ({
    ...styles,
    padding: 0,
    marginRight: -3,
  }),
};

export default styles;
