import queryString from 'query-string';
import { useHistory } from 'react-router-dom';
import { AnyObject } from 'types';
import { useEffect } from 'react';
import { ResourceActions } from 'redux/actions';
import { useDispatch } from 'react-redux';

export function useCleanQueryString(filter: AnyObject, pathname: string) {
  const history = useHistory();
  const dispatch = useDispatch();
  useEffect(() => {
    const query = { ...filter };
    Object.keys(query).forEach(
      key =>
        (query[key] === null ||
          query[key] === '' ||
          (key === 'page' && query[key] === 1) ||
          key === 'perPage' ||
          key === 'searchWithinCategory') &&
        delete query[key],
    );
    const stringified = queryString.stringify(query);
    dispatch(dispatch(ResourceActions.setQueryString({ queryString: stringified })));
    history.push({ pathname: pathname, search: stringified });
  }, [dispatch, filter, history, pathname]);
}
