export const categoriesMaxLevel = 4;
export const perPageDefault = 50;
export const intercomId = 'jan202js';

export const resourceTypes: any = [
  { label: 'GYE Resource', value: 1 },
  { label: 'External Resource', value: 2 },
];

export const perPageOptions = [
  { label: '10', value: 10 },
  { label: '30', value: 30 },
  { label: '50', value: 50 },
  { label: '100', value: 100 },
  { label: '200', value: 200 },
  { label: '500', value: 500 },
];
