import axios from 'axios';
import store from 'redux/store';
import { AuthActions } from 'redux/actions/auth';

const apiClient = axios.create({
  baseURL: process.env.REACT_APP_API_URL,
  responseType: 'json',
});

apiClient.interceptors.request.use(config => {
  const token = localStorage.getItem('token');
  if (token) {
    config.headers.common.Authorization = `Bearer ${token}`;
  }
  return config;
});

apiClient.interceptors.response.use(
  response => response,
  error => {
    const { response } = error;
    if (response) {
      const { status } = response;
      if (status === 401) {
        store.dispatch(AuthActions.logout());
      }
    }
    return Promise.reject(error);
  },
);

export default apiClient;
