import { ILogin, IResetPassword, IResetPasswordConfirm } from 'types';

import api from 'config/axios';
import { AxiosPromise } from 'axios';

export function login(data: ILogin): AxiosPromise {
  return api.post('/auth/login', data);
}

export function resetPasswordRequest(data: IResetPassword): AxiosPromise {
  return api.post('/auth/reset-password', data);
}

export function resetPasswordConfirm(data: IResetPasswordConfirm): AxiosPromise {
  return api.post('/auth/reset-password/confirm', data);
}
