import { defineMessages } from 'react-intl';

export const scope = 'general';

export default defineMessages({
  errorsRequired: {
    id: `${scope}.errors.required`,
    defaultMessage: 'This field is required',
  },
  errorsMax: {
    id: `${scope}.errors.max`,
    defaultMessage: 'Maximum allowed size is {value}',
  },
  errorsMin: {
    id: `${scope}.errors.min`,
    defaultMessage: 'Minimum allowed value is {value}',
  },
  errorsEmail: {
    id: `${scope}.errors.email`,
    defaultMessage: 'Please enter a valid email address',
  },
  errorsPassword: {
    id: `${scope}.errors.password`,
    defaultMessage: 'Password must be at least 6 characters long',
  },
  errorsTokenExpired: {
    id: `${scope}.errors.token.expired`,
    defaultMessage: 'Your token is expired.',
  },
  errorSomethingWentWrong: {
    id: `${scope}.errors.something.wrong`,
    defaultMessage: 'Something went wrong. Please contact GYE for assistance',
  },
});
