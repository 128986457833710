import React, { memo, useMemo } from 'react';
import { AnyObject, ICategory } from '../../types';
import findItemNested, { findParent } from '../../utils/findItemNested';
import { Box } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { frontColors } from '../../config/variables';

interface ISelectedCategoriesProps {
  categoryList: AnyObject[];
  selectedCategory: number;
}

const useStyles = makeStyles(theme => ({
  list: {
    listStyle: 'none',
    padding: 0,
  },
  listItem: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    color: '#5D5D5D',
    fontSize: 20,
    padding: 5,
    '&:first-child': {
      height: '60px',
      fontWeight: 700,
      textTransform: 'uppercase',
      fontSize: 22,
    },
    textAlign: 'center',
    height: '43px',
    fontWeight: 500,
    width: '100%',
    marginBottom: 3,
  },
}));

function SelectedCategories({ categoryList, selectedCategory }: ISelectedCategoriesProps) {
  const parents = useMemo(() => findParent(categoryList, Number(selectedCategory), true), [
    categoryList,
    selectedCategory,
  ]);
  const categories = useMemo<ICategory[]>(
    () => parents.reverse().map((item: string) => findItemNested(categoryList, Number(item), 'children')),
    [categoryList, parents],
  );
  const classes = useStyles();
  const branch = useMemo(() => categoryList.indexOf(categories[0]), [categories, categoryList]);

  return (
    <Box>
      <ul className={classes.list}>
        {categories.map((category, index) => {
          const color = frontColors[branch][index];
          return (
            <li key={category.id} className={classes.listItem} style={{ background: color }}>
              {category.name}
            </li>
          );
        })}
      </ul>
    </Box>
  );
}

export default memo(SelectedCategories);
