import { makeStyles } from '@material-ui/core/styles';
import { greyColor } from '../../../config/variables';
import { darken } from '@material-ui/core';

const useStyles = makeStyles(theme => ({
  avatar: {
    width: 180,
    height: 180,
    [theme.breakpoints.down('md')]: {
      width: 150,
      height: 150,
    },
    [theme.breakpoints.down('sm')]: {
      width: '100%',
      height: 'auto',
      '& img': {
        maxWidth: '100%',
      },
    },
  },
  paper: {
    padding: 15,
    marginBottom: 20,
    backgroundColor: ({ color }: any) => color,
    boxShadow: '0 6px 15px rgba(0,0,0,.05)',
    position: 'relative',
  },
  actions: {
    position: 'absolute',
    left: -30,
    top: 0,
    '& svg': {
      cursor: 'pointer',
      color: greyColor,
      '&:hover': {
        color: darken(greyColor, 1),
      },
    },
  },
  heading: {
    [theme.breakpoints.up('md')]: {
      fontSize: 27,
      fontWeight: 700,
      marginBottom: 5,
    },
    [theme.breakpoints.down('md')]: {
      fontSize: 23,
      fontWeight: 700,
      marginBottom: 5,
      paddingLeft: 8,
    },
  },
  link: {
    [theme.breakpoints.up('md')]: {
      fontSize: 18,
    },
    [theme.breakpoints.down('md')]: {
      fontSize: 16,
    },
    color: '#7A7B7D',
    wordBreak: 'break-word',
  },
  description: {
    [theme.breakpoints.up('md')]: {
      fontSize: 20,
    },
    [theme.breakpoints.down('md')]: {
      fontSize: 17,
      margin: '16px 0',
    },
    fontWeight: 500,
    wordBreak: 'break-word',
  },
  typeText: {
    fontSize: 18,
    fontWeight: 500,
    color: '#5D5D5D',
    [theme.breakpoints.down('md')]: {
      fontSize: 16,
      '& img': {
        width: 20,
      },
    },
  },
  langImage: {
    [theme.breakpoints.down('md')]: {
      '& img': {
        width: 25,
      },
    },
  },
  resourceTypeImage: {
    [theme.breakpoints.down('md')]: {
      '& img': {
        height: 23,
      },
    },
  },
}));

export default useStyles;
