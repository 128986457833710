import { AnyObject } from '../types';

const findItemNested = (arr: any[], itemId: number, nestingKey: any): any =>
  arr.reduce((a, item) => {
    if (a) return a;
    if (item.id === itemId) {
      return item;
    }
    if (item[nestingKey]) {
      return findItemNested(item[nestingKey], itemId, nestingKey);
    }
    return null;
  }, null);

export default findItemNested;

export const countNested = (arr: any[], nestingKey: any): any =>
  arr.reduce((a, item) => {
    a += 1;
    if (item[nestingKey]) {
      a += countNested(item[nestingKey], nestingKey);
    }
    return a;
  }, 0);

export function findParent(root: any, id: number, returnParentIds = false) {
  let node = root;
  let parentId = id;
  const parentIds = [];
  while (node && parentId !== 0) {
    parentIds.push(parentId.toString());
    node = findItemNested(root, parentId, 'children');
    if (node) {
      parentId = node.parent_id;
    }
  }
  return returnParentIds ? parentIds : node;
}

export function findItemsWithChildren(items: AnyObject[]): string[] {
  let ids: string[] = [];
  for (const item of items) {
    if (item.children && item.children.length) {
      ids.push(item.id.toString());
      ids = [...ids, ...findItemsWithChildren(item.children)];
    }
  }
  return ids;
}
