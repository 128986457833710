import { createAction } from 'redux-actions';
import { IAuth, AnyObject as IError, ILogin } from 'types';

enum Type {
  LOGIN = 'LOGIN',
  LOGOUT = 'LOGOUT',
  SET_AUTH = 'SET_AUTH',
  SET_AUTH_ERROR = 'SET_AUTH_ERROR',
  RESET_PASSWORD_REQUEST = 'RESET_PASSWORD_REQUEST',
  RESET_PASSWORD_CONFIRM = 'RESET_PASSWORD_CONFIRM',
}

const setAuthInfo = createAction<IAuth>(Type.SET_AUTH);
const setAuthError = createAction<IError>(Type.SET_AUTH_ERROR);
const login = createAction<ILogin>(Type.LOGIN);
const logout = createAction(Type.LOGOUT);
const resetPasswordRequest = createAction(Type.RESET_PASSWORD_REQUEST);
const resetPasswordConfirm = createAction(Type.RESET_PASSWORD_CONFIRM);

export const AuthActions = {
  Type,
  setAuthInfo,
  setAuthError,
  login,
  logout,
  resetPasswordRequest,
  resetPasswordConfirm,
};
