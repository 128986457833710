import { Theme } from '@material-ui/core';

export default (theme: Theme) => ({
  loaderWrapper: {
    position: 'relative',
    height: '100%',
    borderRadius: 'inherit',
    '&$loaderFullPageWrapper': {
      position: 'fixed',
    },
    '&$loaderWrapperBackground': {
      backgroundColor: 'rgba(0,0,0,.1)',
    },
  },
  loaderWrapperBackground: {},
  loader: {
    color: theme.palette.primary.main,
    position: 'absolute',
    top: '50%',
    left: '50%',
    marginTop: '-25px',
    marginLeft: '-25px',
    zIndex: 100,
  },
  loaderFullPageWrapper: {
    position: 'absolute',
    top: 0,
    bottom: 0,
    left: 0,
    right: 0,
    zIndex: 9999,
  },
});
