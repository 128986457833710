import { makeStyles, Theme } from '@material-ui/core/styles';
import { Link } from '@material-ui/core';
import React from 'react';

const useLinkStyles = makeStyles<Theme, any>(theme => ({
  link: {
    '&:hover': {
      textDecoration: 'none',
    },
  },
}));

export default function LinkWrapper({ children, href, ...rest }: any) {
  const classes = useLinkStyles();
  return href ? (
    <Link href={href} className={classes.link} {...rest}>
      {children}
    </Link>
  ) : (
    children
  );
}
