import { defineMessages } from 'react-intl';

export const scope = 'resource-form';

export default defineMessages({
  fieldCategories: {
    id: `${scope}.field.categories`,
    defaultMessage: 'Categories',
  },
  fieldTitle: {
    id: `${scope}.field.title`,
    defaultMessage: 'Title',
  },
  fieldUrl: {
    id: `${scope}.field.url`,
    defaultMessage: 'URL',
  },
  fieldLanguage: {
    id: `${scope}.field.language`,
    defaultMessage: 'Language',
  },
  fieldType: {
    id: `${scope}.field.type`,
    defaultMessage: 'Type',
  },
  fieldResourceType: {
    id: `${scope}.field.resourceType`,
    defaultMessage: 'Resource Type',
  },
  fieldPhone: {
    id: `${scope}.field.phone`,
    defaultMessage: 'Phone',
  },
  fieldEmail: {
    id: `${scope}.field.email`,
    defaultMessage: 'Email',
  },
  fieldDescription: {
    id: `${scope}.field.description`,
    defaultMessage: 'Description',
  },
  fieldCategoryName: {
    id: `${scope}.field.categoryName`,
    defaultMessage: 'Category Name',
  },
  submit: {
    id: `${scope}.submit`,
    defaultMessage: 'Submit',
  },
});
