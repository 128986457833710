import { red } from '@material-ui/core/colors';
import { createMuiTheme } from '@material-ui/core/styles';
// A custom theme for this app
const theme = createMuiTheme({
  palette: {
    primary: {
      main: '#4476ac',
    },
    secondary: {
      main: '#19857b',
    },
    error: {
      main: red.A400,
    },
    background: {
      default: '#fff',
    },
    text: {
      primary: '#2A2F33',
    },
  },
  typography: {
    fontSize: 16,
    fontFamily: [
      '"Segoe UI"',
      '-apple-system',
      'BlinkMacSystemFont',
      'Roboto',
      '"Helvetica Neue"',
      'Arial',
      'sans-serif',
      '"Apple Color Emoji"',
      '"Segoe UI Emoji"',
      '"Segoe UI Symbol"',
    ].join(','),
  },
  overrides: {
    MuiCssBaseline: {
      '@global': {
        p: {
          margin: 0,
        },
        '.ql-align-center': {
          textAlign: 'center',
        },
        '.ql-align-right': {
          textAlign: 'right',
        },
        '.ql-align-left': {
          textAlign: 'right',
        },
        '.ql-align-justify': {
          textAlign: 'justify',
        },
        '.ql-direction-rtl': {
          direction: 'rtl',
        },
      },
    },
    MuiFormHelperText: {
      contained: {
        marginLeft: 0,
      },
    },
    MuiFab: {
      sizeSmall: {
        height: 30,
        width: 30,
        minHeight: 30,
      },
    },
  },
});

export default theme;
