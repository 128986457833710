import { setConsole } from 'react-query';

setConsole({
  log: window.console.log,
  warn: window.console.warn,
  error: () => {},
});

const config = {
  queries: {
    refetchOnWindowFocus: false,
    refetchOnMount: false,
    retry: false,
  },
};

export default config;
