import { Box, Grid, Drawer as MateriaDrawer } from '@material-ui/core';
import CloseRoundedIcon from '@material-ui/icons/CloseRounded';
import Search from './Search';
import LanguagesSelect from './LanguagesSelect';
import filterStyles from './filterStyles';
import TypesSelect from './TypesSelect';
import Select from 'react-select';
import { resourceTypes } from './constants';
import CategoryTree from './CategoryTree/CategoryTree';
import React, { memo, useCallback, useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { whiteColor } from '../../config/variables';
import findItemNested from '../../utils/findItemNested';
import SearchCheckbox from './SearchCheckbox';
import ResourceTypesSelect from './ResourceTypesSelect';

const useStyles = makeStyles(theme => ({
  search: {
    border: '3px #EBEBEB solid',
    borderRadius: 26,
    paddingLeft: 12,
    background: whiteColor,
    height: 40,
    fontSize: 18,
    width: '90%',
    display: 'flex',
    margin: '0 auto',
    '& svg': {
      fontSize: 30,
      color: '#808080',
    },
  },
  drawer: {
    maxWidth: '90vw',
    '& > div': {
      background: '#f5f5f5',
    },
  },
  filterSelect: {
    [theme.breakpoints.down('sm')]: {
      '& .css-1kao8ia-control': {
        background: whiteColor,
        height: 40,
        minWidth: 200,
        alignItems: 'flex-start',
      },
      '& .css-1xg8mv3-placeholder, .css-pd2oaf-singleValue': {
        fontSize: 18,
      },
    },
  },
  closeButton: {
    color: '#808080',
    width: 60,
    height: 60,
    cursor: 'pointer',
    position: 'absolute',
    top: 15,
    right: 15,
    outline: 'none',
  },
}));

function Drawer({
  drawerOpen,
  setDrawerOpen,
  filter,
  categories,
  category,
  setFilter,
  searchWithinCategory,
  setSearchWithinCategory,
}: any) {
  const classes = useStyles();
  const [filterLocal, setFilterLocal] = useState(filter);
  const [searchWithinCategoryLocal, setSearchWithinCategoryLocal] = useState(searchWithinCategory);

  const handleCloseDrawer = useCallback(
    filter => {
      setFilter(filter);
      setSearchWithinCategory(searchWithinCategoryLocal);
      setDrawerOpen(false);
    },
    [searchWithinCategoryLocal, setDrawerOpen, setFilter, setSearchWithinCategory],
  );

  const handleSetFilter = useCallback(
    async (key: any, value: any) => {
      const newObj = key === 'category' && category !== value ? { search: '' } : { ...filterLocal };
      newObj[key] = value;
      setFilterLocal(newObj);
      if (key === 'category') {
        const catObj = findItemNested(categories, Number(value), 'children');
        if (catObj && (!catObj.children || catObj.children.length === 0)) {
          handleCloseDrawer(newObj);
        }
      }
    },
    [categories, category, filterLocal, handleCloseDrawer],
  );

  return (
    <MateriaDrawer
      classes={{ paper: classes.drawer }}
      anchor="right"
      open={drawerOpen}
      onClose={() => handleCloseDrawer(filterLocal)}
    >
      <Box pt={12} pb={6} position="relative" px={1}>
        <CloseRoundedIcon className={classes.closeButton} onClick={() => handleCloseDrawer(filterLocal)} />
        <Grid container spacing={1} justify="center">
          <Grid component={Box} item display="flex">
            <Search
              handleSearch={(e: any) => handleSetFilter('search', e.target.value)}
              searchString={filterLocal.search}
              className={classes.search}
            />
          </Grid>
          <Grid item>
            <LanguagesSelect
              homePage
              searchWithinCategory={searchWithinCategoryLocal}
              className={classes.filterSelect}
              filter={{
                language: filterLocal.language,
                type: filterLocal.type,
                resource_type: filterLocal.resource_type,
              }}
              value={filterLocal.language}
              type="reactSelect"
              styles={filterStyles}
              onChange={(v: any) => handleSetFilter('language', v ? v.value : v)}
            />
          </Grid>
          <Grid item>
            <TypesSelect
              homePage
              searchWithinCategory={searchWithinCategoryLocal}
              className={classes.filterSelect}
              filter={{
                language: filterLocal.language,
                type: filterLocal.type,
                resource_type: filterLocal.resource_type,
              }}
              value={filterLocal.type}
              type="reactSelect"
              styles={filterStyles}
              onChange={(v: any) => handleSetFilter('type', v ? v.value : v)}
            />
          </Grid>
          <Grid item>
            <ResourceTypesSelect
              homePage
              searchWithinCategory={searchWithinCategoryLocal}
              className={classes.filterSelect}
              filter={{
                language: filterLocal.language,
                type: filterLocal.type,
                resource_type: filterLocal.resource_type,
              }}
              value={filterLocal.resource_type}
              onChange={(v: any) => handleSetFilter('resource_type', v ? v.value : v)}
              placeholder="Resource Type"
              styles={filterStyles}
            />
          </Grid>
        </Grid>
        <Box display="flex" justifyContent="center" mt={3}>
          <SearchCheckbox checked={searchWithinCategoryLocal} setChecked={setSearchWithinCategoryLocal} />
        </Box>
      </Box>
      <CategoryTree selectedCat={filterLocal.category} categories={categories} handleSetFilter={handleSetFilter} />
    </MateriaDrawer>
  );
}

export default memo(Drawer);
