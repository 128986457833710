import React, { memo } from 'react';
import { InputBase, InputAdornment } from '@material-ui/core';
import SearchIcon from '@material-ui/icons/Search';

function Search({ handleSearch, searchString, ...rest }: any) {
  return (
    <InputBase
      startAdornment={
        <InputAdornment position="start">
          <SearchIcon />
        </InputAdornment>
      }
      placeholder="Search"
      label="Search"
      autoComplete="off"
      onChange={handleSearch}
      value={searchString}
      {...rest}
    />
  );
}

export default memo(Search);
