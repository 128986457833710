export function capitalize(value: string) {
  return value.charAt(0).toUpperCase() + value.slice(1);
}

export function formatWebsite(link?: string): string {
  const website = link || '';

  return website && !/^http(s?)?:\/\//i.test(website) ? `https://${website}` : website;
}

export function stripURL(url: string) {
  return url ? url.replace(/^(?:https?:\/\/)?(?:www\.)?/i, '').replace(/\/$/, '') : url;
}

export function formatURL(urlString: string) {
  const url = new URL(urlString.indexOf('http') !== -1 ? urlString : `http://${urlString}`);
  const parts = url.pathname.split('/');
  let last = url.pathname !== '/' && parts.length > 2 ? parts[parts.length - 1] : '';

  if (url.search) {
    const searchParams = new URLSearchParams(url.search);
    const params: any = [];
    for (const entry of searchParams.entries()) {
      params.push(`${entry[0]}=${entry[1]}`);
    }
    if (params.length > 1) {
      last = params[params.length - 1];
    }
  }
  last = decodeURI(last);
  last = last && last.length > 20 ? last.substring(last.length - 20) : last;
  return last ? `${url.host} > ... > ${last}` : urlString;
}

export function convertUrlToLink(text: string) {
  const pattern = /(https?:\/\/[^\s]+)/g;
  return text.replace(pattern, (url: string) => `<a target="_blank" href="${url}">${url}</a>`);
}
