import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { push } from 'connected-react-router';
import { IRootState } from 'redux/reducers/state';
import Loader from '../../common/Loader';

export default (WrappedComponent: React.ComponentType) =>
  function Auth(props: object) {
    const accessToken = useSelector((state: IRootState): string => state.auth.token);
    const dispatch = useDispatch();
    useEffect(() => {
      if (!accessToken) {
        dispatch(push('/login'));
      }
    }, [accessToken, dispatch]);
    return (
      <Loader loading={Boolean(!accessToken)}>
        <WrappedComponent {...props} />
      </Loader>
    );
  };
