import React from 'react';
import cx from 'classnames';
import CircularProgress from '@material-ui/core/CircularProgress';
import makeStyles from '@material-ui/core/styles/makeStyles';

import styles from './styles';

interface Props {
  loading: boolean;
  className?: string;
  children?: React.ReactNode;
  fullPage?: boolean;
  background?: boolean;
  notUnmount?: boolean;
}

// @ts-ignore
const useStyles = makeStyles(styles);

const Loader: React.FC<Props> = props => {
  const { className = '', fullPage = false, loading, notUnmount = false, children = '', background = false } = props;
  const classes = useStyles();

  return (
    <>
      {loading && (
        <div
          className={cx(classes.loaderWrapper, className, {
            [classes.loaderFullPageWrapper]: fullPage,
            [classes.loaderWrapperBackground]: background,
          })}
        >
          <CircularProgress className={classes.loader} size={50} />
        </div>
      )}
      {(notUnmount || !loading) && children}
    </>
  );
};

export default Loader;
