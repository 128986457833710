import api from 'config/axios';

export function list(params: any = {}) {
  return api.get('/languages', params);
}

export function item(id: any) {
  return api.get(`/languages/${id}`);
}

export function add(data: any) {
  return api.post('/languages', data);
}

export function edit(id: number, data: any) {
  return api.put(`/languages/${id}`, data);
}

export function del(id: any) {
  return api.delete(`/languages/${id}`);
}
