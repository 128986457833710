import React, { Suspense } from 'react';
import { Route, Switch } from 'react-router-dom';
import Auth from 'components/auth/decorators/auth';
import Loader from '../components/common/Loader';
import HomePage from '../pages/dashboard/HomePage';
const LoginPage = React.lazy(() => import('../pages/auth/LoginPage'));
const ResourcesPage = React.lazy(() => import('../pages/admin/ResourcesPage'));
const AddResourcePage = React.lazy(() => import('../pages/admin/AddResourcePage'));
const EditResourcePage = React.lazy(() => import('../pages/admin/EditResourcePage'));
const CategoriesPage = React.lazy(() => import('../pages/admin/CategoriesPage'));
const TypesPage = React.lazy(() => import('../pages/admin/TypesPage'));
const LanguagesPage = React.lazy(() => import('../pages/admin/LanguagesPage'));

export default function MainRouter() {
  return (
    <Suspense fallback={<Loader fullPage={true} loading={true} children={''} background={true} />}>
      <Switch>
        <Route exact path="/login" component={LoginPage} />
        <Route exact path="/admin" component={Auth(ResourcesPage)} />
        <Route exact path="/admin/resources/add" component={Auth(AddResourcePage)} />
        <Route exact path="/admin/resources/:id" component={Auth(EditResourcePage)} />
        <Route exact path="/admin/categories" component={Auth(CategoriesPage)} />
        <Route exact path="/admin/types" component={Auth(TypesPage)} />
        <Route exact path="/admin/languages" component={Auth(LanguagesPage)} />
        <Route exact path="/" component={HomePage} />
      </Switch>
    </Suspense>
  );
}
