import PubSub from 'pubsub-js';

import { SNACKBAR_SUBMISSION, SNACKBAR_SUCCESS, SNACKBAR_ERROR, SNACKBAR_WARNING, SNACKBAR_INFO } from '../constants';

export function notification(message: string, options = {}) {
  PubSub.publish(SNACKBAR_SUBMISSION, { message, ...options });
}

export function success(message: string, options = {}) {
  notification(message, { severity: SNACKBAR_SUCCESS, ...options });
}

export function error(message: string, options = {}) {
  notification(message, { severity: SNACKBAR_ERROR, ...options });
}

export function warning(message: string, options = {}) {
  notification(message, { severity: SNACKBAR_WARNING, ...options });
}

export function info(message: string, options = {}) {
  notification(message, { severity: SNACKBAR_INFO, ...options });
}

export function generate(entity: string, action: string) {
  return `${entity} has been successfully ${action}`;
}
