import api from 'config/axios';

export function list(data: any) {
  return api.get('/resources', data);
}

export function item(id: any) {
  return api.get(`/resources/${id}`);
}

export function add(data: any) {
  return api.post('/resources', data);
}

export function edit(id: number, data: any) {
  return api.patch(`/resources/${id}`, data);
}

export function patch(id: number, data: any) {
  return api.patch(`/resources/${id}`, data);
}

export function del(id: number) {
  return api.delete(`/resources/${id}`);
}

export function reorder(data: any) {
  return api.post(`/resources/reorder`, data);
}

export function uploadImage(resourceId: string, file: any) {
  const data = new FormData();
  data.append('file', file);
  data.append('resource_id', resourceId);

  return api.post(`/resources/${resourceId}/upload`, data, {
    headers: {
      'Content-Type': `multipart/form-data`,
    },
  });
}
