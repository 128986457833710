import React from 'react';
import { Helmet } from 'react-helmet-async';
import HomePageContainer from '../../containers/HomePage';
import HomePageLayout from '../../components/layouts/HomePageLayout';

export default function HomePage() {
  return (
    <HomePageLayout>
      <Helmet>
        <title>GYEWORLD</title>
        <meta name="description" content="GYEWORLD" />
      </Helmet>
      <HomePageContainer />
    </HomePageLayout>
  );
}
