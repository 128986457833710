import api from 'config/axios';

export function list(params: any = {}) {
  return api.get('/types', params);
}

export function resourceTypeList(params: any = {}) {
  return api.get('/resource_types', params);
}

export function item(id: any) {
  return api.get(`/types/${id}`);
}

export function add(data: any) {
  return api.post('/types', data);
}

export function edit(id: number, data: any) {
  return api.put(`/types/${id}`, data);
}

export function del(id: any) {
  return api.delete(`/types/${id}`);
}

export function uploadImage(id: string, file: any) {
  const data = new FormData();
  data.append('file', file);
  data.append('id', id);

  return api.post(`/types/${id}/upload`, data, {
    headers: {
      'Content-Type': `multipart/form-data`,
    },
  });
}
