import { handleActions } from 'redux-actions';
import { IAuth } from '../../types';
import { AuthActions } from '../actions';

export const initialState: IAuth = {
  token: '',
  error: {},
};

export const AuthReducer = handleActions<IAuth | null, IAuth>(
  {
    [AuthActions.Type.SET_AUTH]: (state, action) => action.payload,
    [AuthActions.Type.SET_AUTH_ERROR]: (state, action) => action.payload,
  },
  initialState,
);
