import React, { memo, useContext, useMemo } from 'react';

import {
  Paper,
  Grid,
  Avatar,
  Typography,
  Box,
  Tooltip,
  Link,
  useMediaQuery,
  useTheme,
  Hidden,
} from '@material-ui/core';
import DeleteOutlinedIcon from '@material-ui/icons/DeleteOutlined';
import EditOutlinedIcon from '@material-ui/icons/EditOutlined';

import Image from './../Image';
import Logo from 'assets/logo.svg';
import { findParent } from 'utils/findItemNested';
import { frontColors } from 'config/variables';
import LinkWrapper from './LinkWrapper';
import { resourceTypes } from '../constants';
import { Link as RouterLink } from 'react-router-dom';
import UserTypeContext from '../context/userType';
import { formatURL, stripURL } from 'utils/string';
import { apiHost } from '../../../constants/global';
import useStyles from './styles';

function Resource({
  id,
  title,
  image,
  phone,
  email,
  url,
  languages,
  types,
  description,
  resource_types,
  categories,
  categoryList,
  selectedCat,
  onDeleteClick,
}: any) {
  const theme = useTheme();
  const matches = useMediaQuery(theme.breakpoints.up('md'));
  const selectedParent = categoryList.find((cat: { id: any }) => cat.id === Number(selectedCat));
  const parent = selectedParent
    ? selectedParent
    : findParent(categoryList, categories.length ? categories[0]['id'] : 0);
  const index = categoryList.indexOf(parent);
  const color = frontColors[index] ? frontColors[index][3] : null;
  const classes = useStyles({ color });
  const userType = useContext(UserTypeContext);
  const resourceTypesArray = useMemo(() => {
    const array = JSON.parse(resource_types);
    return Array.isArray(array) ? array.sort() : [];
  }, [resource_types]);

  const spacing = useMemo(() => (matches ? 5 : 1), [matches]);
  return (
    <Box position="relative">
      {userType === 'admin' && (
        <Box display="flex" flexDirection="column" className={classes.actions}>
          <Link component={RouterLink} to={`/admin/resources/${id}`}>
            <EditOutlinedIcon />
          </Link>
          <DeleteOutlinedIcon onClick={() => onDeleteClick(id)} />
        </Box>
      )}
      <LinkWrapper href={url} target="_blank">
        <Paper className={classes.paper} variant="outlined">
          <Grid container spacing={spacing}>
            <Grid item xs={4} md="auto">
              {image ? (
                <Avatar
                  variant="rounded"
                  className={classes.avatar}
                  alt={title}
                  src={image ? `${apiHost}/images/resources/${image}` : ''}
                />
              ) : (
                <Box
                  justifyContent="center"
                  display="flex"
                  borderRadius={4}
                  bgcolor="#d4d4d4"
                  className={classes.avatar}
                >
                  <img src={Logo} alt={title} />
                </Box>
              )}
            </Grid>
            <Grid item xs={8} component={Box} display={{ md: 'none' }}>
              <Typography gutterBottom variant="h5" className={classes.heading}>
                {title}
              </Typography>
            </Grid>
            <Grid item xs={12} sm container>
              <Grid item xs={12} container>
                <Grid item sm={6} container direction="column" alignItems="stretch">
                  <Hidden smDown>
                    <Box pt={1} width="100%">
                      <Typography gutterBottom variant="h5" className={classes.heading}>
                        {title}
                      </Typography>
                    </Box>
                  </Hidden>
                  <Box display="flex" flexGrow={1} alignItems="center" width="100%">
                    <Box width="100%">
                      {url && (
                        <Typography className={classes.link}>
                          <Hidden smDown>
                            <strong>URL:</strong>
                          </Hidden>{' '}
                          {formatURL(stripURL(url))}
                        </Typography>
                      )}
                      {phone && (
                        <Typography className={classes.link}>
                          <strong>Tel:</strong> {phone}
                        </Typography>
                      )}
                      {email && (
                        <Typography className={classes.link}>
                          <strong>E-mail:</strong> {email}
                        </Typography>
                      )}
                    </Box>
                  </Box>
                </Grid>
                <Grid item md={6}>
                  <Box p={matches ? 2 : 0} className={classes.description}>
                    <div dangerouslySetInnerHTML={{ __html: description }} />
                  </Box>
                </Grid>
              </Grid>
              <Grid item xs={12} alignItems="flex-end" container>
                <Box
                  display="flex"
                  flexDirection="row"
                  mt={matches ? 0 : 1}
                  pb={1}
                  flexWrap={matches ? 'nowrap' : 'wrap'}
                >
                  {languages.map((language: any) => (
                    <Tooltip key={language.id} title={`Language: ${language.language}`} arrow placement="top">
                      <Box mr={2} display="flex" alignContent="center" className={classes.langImage}>
                        <Image alt={language.language} src={`/images/icons/lang/lang${language.id}.svg`} />
                      </Box>
                    </Tooltip>
                  ))}
                  {types.map((type: any) => (
                    <Tooltip key={type.id} title="Resource Type" arrow placement="top">
                      <Box mr={2} display="flex" alignContent="center" className={classes.typeText}>
                        {type.image && (
                          <Image
                            style={{ maxHeight: 22, maxWidth: 22, alignSelf: 'center' }}
                            alt={type.type}
                            display="flex"
                            src={`${apiHost}/images/types/${type.image}`}
                          />
                        )}{' '}
                        <Box component="span" ml={1}>
                          {type.type}
                        </Box>
                      </Box>
                    </Tooltip>
                  ))}
                  {resourceTypesArray.map((resourceType: any) => (
                    <Tooltip
                      key={resourceType}
                      title={`${resourceType === resourceTypes[0].value ? 'GYE' : 'External'} Resource`}
                      arrow
                      placement="top"
                    >
                      <Box mr={2} display="flex" alignContent="center" className={classes.resourceTypeImage}>
                        <Image display="flex" src={`/images/icons/resource_type${resourceType}.svg`} />{' '}
                      </Box>
                    </Tooltip>
                  ))}
                </Box>
              </Grid>
            </Grid>
          </Grid>
        </Paper>
      </LinkWrapper>
    </Box>
  );
}

export default memo(Resource);
