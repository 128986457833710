import { Checkbox, FormControlLabel } from '@material-ui/core';
import cx from 'classnames';
import React, { memo } from 'react';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(theme => ({
  checkbox: {
    fontSize: 18,
    color: '#8E8E8E',
    [theme.breakpoints.down('sm')]: {
      fontSize: 14,
    },
  },
  icon: {
    borderRadius: 11,
    width: 33,
    height: 33,
    [theme.breakpoints.down('sm')]: {
      width: 26,
      height: 26,
      borderRadius: 8,
    },
    border: '#EBEBEB 3px solid',
    '$root.Mui-focusVisible &': {
      outline: '2px auto rgba(19,124,189,.6)',
      outlineOffset: 2,
    },
    'input:disabled ~ &': {
      boxShadow: 'none',
      background: 'rgba(206,217,224,.5)',
    },
  },
  checkedIcon: {
    backgroundImage: 'linear-gradient(180deg,hsla(0,0%,100%,.1),hsla(0,0%,100%,0))',
    '&:before': {
      display: 'block',
      width: 33,
      height: 33,
      [theme.breakpoints.down('sm')]: {
        width: 26,
        height: 26,
      },
      backgroundImage: `url("data:image/svg+xml;charset=utf-8,%3Csvg xmlns=\\'http://www.w3.org/2000/svg\\' viewBox='1.5 1.5 16 16'%3E%3Cpath fill-rule='evenodd' clip-rule='evenodd' d='M12 5c-.28 0-.53.11-.71.29L7 9.59l-2.29-2.3a1.003 1.003 0 00-1.42 1.42l3 3c.18.18.43.29.71.29s.53-.11.71-.29l5-5A1.003 1.003 0 0012 5z' fill='%23808080'/%3E%3C/svg%3E")`,
      content: '""',
    },
  },
}));

function SearchCheckbox({ checked, setChecked }: { checked: boolean; setChecked: (checked: boolean) => void }) {
  const classes = useStyles();
  return (
    <FormControlLabel
      classes={{ label: classes.checkbox }}
      control={
        <Checkbox
          icon={<span className={classes.icon} />}
          checkedIcon={<span className={cx(classes.icon, classes.checkedIcon)} />}
          checked={checked}
          onChange={e => setChecked(e.target.checked)}
          name="checkedB"
          color="primary"
        />
      }
      label="Search Within Selected Category"
    />
  );
}

export default memo(SearchCheckbox);
