import {
  TextField,
  InputLabel,
  Checkbox,
  FormControlLabel,
  FormControl,
  Select,
  FormHelperText,
  createStyles,
} from '@material-ui/core';
import React from 'react';
import { useIntl } from 'react-intl';
import ReactSelect from 'react-select';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(() =>
  createStyles({
    quillContainer: {
      marginBottom: 20,
      '& .ql-editor': {
        minHeight: 250,
        fontSize: 16,
      },
    },
  }),
);

const modules = {
  toolbar: [
    [{ size: [] }],
    ['bold', 'italic', 'underline', 'strike', 'link'],
    [{ direction: 'rtl' }],
    [{ align: [] }],
    ['clean'],
  ],
  clipboard: {
    matchVisual: false,
  },
};

const formats = ['size', 'bold', 'italic', 'underline', 'strike', 'blockquote', 'link', 'direction', 'align'];

export default function FormInput({
  label,
  type,
  options,
  input,
  meta: { touched, invalid, error },
  errorMsg,
  ...rest
}: any) {
  const intl = useIntl();
  const classes = useStyles();
  const isInvalid = !!errorMsg || invalid;
  const errorMessage = errorMsg
    ? [errorMsg]
    : /validation.error.\w+/.test(error)
    ? intl.formatMessage({ id: error })
    : error;
  switch (type) {
    case 'checkbox':
      return (
        <div>
          <FormControlLabel control={<Checkbox checked={!!input.value} onChange={input.onChange} />} label={label} />
        </div>
      );
    case 'select':
      return (
        <FormControl classes={rest.formControlClasses} {...rest} error={isInvalid}>
          <InputLabel classes={rest.labelClasses}>{label}</InputLabel>
          <Select native variant="outlined" label={label} {...input} classes={rest.classes}>
            <option aria-label="None" value="" />
            {options &&
              options.map((item: any) => (
                <option key={item.value} value={item.value}>
                  {item.label}
                </option>
              ))}
          </Select>
          {isInvalid && <FormHelperText>{errorMessage}</FormHelperText>}
        </FormControl>
      );
    case 'reactSelect':
      input.value = options.filter((item: any) => input.value.includes(item.value));
      return (
        <FormControl fullWidth error={isInvalid}>
          <ReactSelect
            {...input}
            {...rest}
            invalid={isInvalid}
            styles={rest.styles}
            onChange={(value: any) => input.onChange(value && value.map((item: any) => item.value))}
            onBlur={() => {}}
            options={options}
          />
          {isInvalid && <FormHelperText>{errorMessage}</FormHelperText>}
        </FormControl>
      );
    case 'editor':
      return (
        <FormControl fullWidth error={isInvalid}>
          <ReactQuill
            theme="snow"
            modules={modules}
            formats={formats}
            value={input.value}
            onChange={input.onChange}
            className={classes.quillContainer}
          />
          {isInvalid && <FormHelperText>{errorMessage}</FormHelperText>}
        </FormControl>
      );
    default:
      return (
        <TextField
          label={label}
          placeholder={label}
          error={touched && isInvalid}
          helperText={touched && errorMessage}
          type={type === 'password' ? type : 'text'}
          {...input}
          {...rest}
        />
      );
  }
}
