import { createAction } from 'redux-actions';

enum Type {
  SET_QUERY_STRING = 'SET_QUERY_STRING',
}

const setQueryString = createAction(Type.SET_QUERY_STRING);

export const ResourceActions = {
  Type,
  setQueryString,
};
