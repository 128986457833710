import React, { useState, memo, useEffect, useRef } from 'react';

function Image({ fallback, ...props }: any) {
  const [state, setState] = useState<any>(true);

  const isMounted = useRef(false);

  useEffect(() => {
    isMounted.current = true;
  }, []);

  useEffect(() => {
    return () => {
      isMounted.current = false;
    };
  }, []);

  return state ? (
    <img
      alt={props.alt}
      ref={(input: any) => {
        if (!input) {
          return;
        }
        const img = input;
        img.onerror = () => {
          if (isMounted.current) {
            setState(false);
          }
        };
        if (img.complete && isMounted.current) {
          setState(true);
        }
      }}
      {...props}
    />
  ) : (
    <></>
  );
}

export default memo(Image);
