import './wdyr';
import React from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import { HelmetProvider } from 'react-helmet-async';
import { ReactQueryConfigProvider } from 'react-query';
import store, { history } from './redux/store';
import MainRouter from './router';
import { ConnectedRouter } from 'connected-react-router';
import { MuiThemeProvider } from '@material-ui/core';
import CssBaseline from '@material-ui/core/CssBaseline';
import { SnackbarsProvider } from 'components/snackbars';
import { IntlProvider } from 'react-intl';

import theme from './config/theme';
import { AuthActions } from './redux/actions';
import { IAuth } from './types';
import { translationMessages } from 'config/i18n';
import reactQuery from './config/reactQuery';

const token = localStorage.getItem('token');
if (token) {
  store.dispatch(AuthActions.setAuthInfo({ token } as IAuth));
}

const app = (
  <Provider store={store}>
    <IntlProvider locale="en" key="en" messages={translationMessages['en']}>
      <ConnectedRouter history={history}>
        <MuiThemeProvider theme={theme}>
          <SnackbarsProvider>
            <HelmetProvider>
              <ReactQueryConfigProvider config={reactQuery}>
                <MainRouter />
                <CssBaseline />
              </ReactQueryConfigProvider>
            </HelmetProvider>
          </SnackbarsProvider>
        </MuiThemeProvider>
      </ConnectedRouter>
    </IntlProvider>
  </Provider>
);

ReactDOM.render(app, document.getElementById('root'));
