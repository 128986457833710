import { Theme } from '@material-ui/core';
import { createStyles } from '@material-ui/core/styles';
import {
  textPrimaryColor,
  textLightGreyColor,
  primaryColor,
  successColor,
  dangerColor,
  warningColor,
  whiteColor,
  boxShadow,
  borderRadiusSize,
} from 'config/variables';

export default ({ breakpoints }: Theme) =>
  createStyles({
    alertRoot: {
      backgroundColor: whiteColor,
      borderWidth: 1,
      borderStyle: 'solid',
      padding: '0 30px 0 0',
      minHeight: 50,
      minWidth: 300,
      maxWidth: '100%',
      borderRadius: borderRadiusSize,
      ...boxShadow,
      [breakpoints.up('sm')]: {
        minWidth: 400,
        maxWidth: 500,
        minHeight: 60,
        paddingRight: 35,
      },
      [breakpoints.up('md')]: {
        minWidth: 400,
        maxWidth: 600,
        minHeight: 70,
      },
    },

    // Alert Color
    error: {
      borderColor: dangerColor,
      '& $alertIcon': {
        color: whiteColor,
        backgroundColor: dangerColor,
      },
    },
    success: {
      borderColor: successColor,
      '& $alertIcon': {
        color: whiteColor,
        backgroundColor: successColor,
      },
    },
    warning: {
      borderColor: warningColor,
      '& $alertIcon': {
        color: whiteColor,
        backgroundColor: warningColor,
      },
    },
    info: {
      borderColor: primaryColor,
      '& $alertIcon': {
        color: whiteColor,
        backgroundColor: primaryColor,
      },
    },
    alertIcon: {
      color: whiteColor,
      fontSize: 26,
      width: 50,
      minWidth: 50,
      alignItems: 'center',
      justifyContent: 'center',
      opacity: 1,
      marginRight: 20,
      padding: 0,
      borderRadius: `${borderRadiusSize / 2}px 0 0 ${borderRadiusSize / 2}px`,
      [breakpoints.up('sm')]: {
        fontSize: 30,
        width: 60,
        minWidth: 60,
      },
      [breakpoints.up('md')]: {
        fontSize: 32,
        width: 70,
        minWidth: 70,
      },
    },
    alertMessage: {
      color: textPrimaryColor,
      display: 'flex',
      alignItems: 'center',
      fontSize: 14,
      [breakpoints.up('sm')]: {
        fontSize: 16,
      },
      [breakpoints.up('md')]: {
        fontSize: 18,
      },
    },
    alertAction: {
      color: textLightGreyColor,
      display: 'block',
      position: 'absolute',
      top: 2,
      right: 2,
      margin: 0,
      padding: 0,
      '& svg': {
        fontSize: 20,
      },
      [breakpoints.up('sm')]: {
        top: 5,
        right: 5,
      },
    },
  });
